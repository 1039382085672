import { Button, Form, Divider } from "antd";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import GodcmdConfig from "./GodcmdConfig";
import MidjourneyProxyOnWechat from "./MidjourneyProxyOnWechat";
import { formItemLayout, getApiUrl } from "../shared";
import CcliteConfig from "./CcliteConfig";
import Sum4allConfig from "./Sum4allConfig";

interface Godcmd {
  password: string;
  admin_users: string[];
}

interface MidjourneyProxy {
  mj_url: string;
  mj_api_secret: string;
  mj_tip: boolean;
  mj_admin_password: string;
  discordapp_proxy: string;
  show_img_url: boolean;
  daily_limit: number;
  imagine_prefix: string[];
  fetch_prefix: string[];
  up_prefix: string[];
  pad_prefix: string[];
  blend_prefix: string[];
  describe_prefix: string[];
  queue_prefix: string[];
  end_prefix: string[];
  reroll_prefix: string[];
}

interface Cclite {
  alapi_key: string;
  bing_subscription_key: string;
  google_api_key: string;
  google_cx_id: string;
  open_ai_api_key: string;
  open_ai_api_base: string;
  functions_openai_model: string;
  assistant_openai_model: string;
  getwt_key: string;
  // cc_api_base: string;
  // prompt: {
  //   [key: string]: string;
  // };
}

interface Sum4all {
  sum_service: string;
  file_sum: boolean;
  qa_prefix: string;
  search_prefix: string;
  search_service: string;
  bibigpt_key: string;
  sum4all_key: string;
  perplexity_key: string;
  outputLanguage: string;
  group_sharing: boolean;
  opensum_key: string;
  open_ai_api_key: string;
  model: string;
  open_ai_api_base: string;
  prompt: string;
  search_prompt: string;
  image_service: string;
  xunfei_app_id: string;
  xunfei_api_key: string;
  xunfei_api_secret: string;
}

interface ConfigData {
  godcmd: Godcmd;
  MidJourney: MidjourneyProxy;
  cclite: Cclite;
  sum4all: Sum4all;
  [key: string]: any;
}

const FormPlugins: React.FC = () => {
  const [configData, setConfigData] = useState<ConfigData>({
    godcmd: {
      password: "1234",
      admin_users: [],
    },
    MidJourney: {
      mj_url: "",
      mj_api_secret: "",
      mj_tip: true,
      mj_admin_password: "1234",
      show_img_url: false,
      discordapp_proxy: "",
      daily_limit: 10,
      imagine_prefix: ["/i", "/mj"],
      fetch_prefix: ["/f"],
      up_prefix: ["/u"],
      pad_prefix: ["/p"],
      blend_prefix: ["/b"],
      describe_prefix: ["/d"],
      queue_prefix: ["/q"],
      end_prefix: ["/e"],
      reroll_prefix: ["/r"],
    },
    cclite: {
      alapi_key: "",
      bing_subscription_key: "",
      google_api_key: "",
      google_cx_id: "",
      open_ai_api_key: "",
      open_ai_api_base: "https://api.openai.com/v1",
      functions_openai_model: "gpt-3.5-turbo",
      assistant_openai_model: "gpt-3.5-turbo-16k",
      getwt_key: "",
      // cc_api_base: "https://api.lfei.cc",
      // prompt: {},
    },
    sum4all: {
      sum_service: "关闭",
      file_sum: false,
      search_service: "关闭",

      qa_prefix: "问",
      search_prefix: "搜",
      bibigpt_key: "",
      sum4all_key: "",
      perplexity_key: "",
      outputLanguage: "zh-CN",
      group_sharing: false,
      opensum_key: "",
      open_ai_api_key: "",
      model: "gpt-3.5-turbo-16k",
      open_ai_api_base: "https://api.openai.com/v1",
      prompt:
        "你是一个新闻专家，我会给你发一些网页内容，请你用简单明了的语言做总结。格式如下：📌总结\n一句话讲清楚整篇文章的核心观点，控制在30字左右。\n\n💡要点\n用数字序号列出来3-5个文章的核心内容，尽量使用emoji让你的表达更生动",
      search_prompt: "你是一个信息检索专家，请你用简单明了的语言，对你收到的内容做总结。尽量使用emoji让你的表达更生动",
      image_service: "关闭",
      xunfei_app_id: "",
      xunfei_api_key: "",
      xunfei_api_secret: "",
    },
  });

  const updateConfigData = (keyL1: string, keyL2: string, value: string | number | boolean | string[]) => {
    setConfigData((prevData) => ({
      ...prevData,
      [keyL1]: {
        ...prevData[keyL1],
        [keyL2]: value,
      },
    }));
  };

  // const updateConfigDataL3 = (
  //   keyL1: string,
  //   keyL2: string,
  //   keyL3: string,
  //   value: string | number | boolean | string[]
  // ) => {
  //   setConfigData((prevData) => ({
  //     ...prevData,
  //     [keyL1]: {
  //       ...prevData[keyL1],
  //       [keyL2]: {
  //         ...prevData[keyL2],
  //         [keyL3]: value,
  //       },
  //     },
  //   }));
  // };

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const apiURL = getApiUrl("/api/config_plugins");

        const response = await axios.get(apiURL);
        setConfigData((prevData) => ({
          ...prevData,
          ...response.data,
        }));
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };

    fetchConfigData();
  }, []);

  const onFinish = async (values: any) => {
    console.log("Submit:", values);

    try {
      const apiURL = getApiUrl("/api/generate_config_plugins");

      const response = await axios.post(apiURL, configData);
      if (response.data.success) {
        alert("插件配置保存成功，正在重载插件");
      } else {
        alert("Error: " + response.data.error);
      }
    } catch (error) {
      alert("Network error!");
    }
  };


  // const buttonItemLayout = {
  //   wrapperCol: { span: 14, offset: 6 },
  // };

  const parentRef = useRef<HTMLDivElement>(null);
  const [buttonStyle, setButtonStyle] = useState<React.CSSProperties>({});

  const handleScroll = () => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();

      if (window.innerHeight > parentRect.bottom || window.innerHeight - parentRect.top < 100) {
        // 如果按钮超出了父组件，将其固定在父组件的底部
        setButtonStyle({ position: "absolute" });
      } else {
        // 否则，让它浮动
        setButtonStyle({ position: "fixed" });
      }
    } else {
      setButtonStyle({ position: "absolute" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={parentRef} className="parent">
      <Form {...formItemLayout} className="config-form" layout="horizontal" onFinish={onFinish}>
        <Divider orientation="left">
          <div id="godcmd">Godcmd管理</div>
        </Divider>
        <GodcmdConfig
          password={configData.godcmd.password}
          setPassword={(value) => updateConfigData("godcmd", "password", value)}
          // adminUsers={configData.godcmd.admin_users}
          // setAdminUsers={(value) => updateConfigData("godcmd", "admin_users", value)}
        />
        <Divider orientation="left">
          <div id="midjourney">Midjourney代理</div>
        </Divider>
        <MidjourneyProxyOnWechat
          mjUrl={configData.MidJourney.mj_url}
          setMjUrl={(value) => updateConfigData("MidJourney", "mj_url", value)}
          mjApiSecret={configData.MidJourney.mj_api_secret}
          setMjApiSecret={(value) => updateConfigData("MidJourney", "mj_api_secret", value)}
          mjTip={configData.MidJourney.mj_tip}
          setMjTip={(value) => updateConfigData("MidJourney", "mj_tip", value)}
          mjAdminPassword={configData.MidJourney.mj_admin_password}
          setMjAdminPassword={(value) => updateConfigData("MidJourney", "mj_admin_password", value)}
          discordappProxy={configData.MidJourney.discordapp_proxy}
          setDiscordappProxy={(value) => updateConfigData("MidJourney", "discordapp_proxy", value)}
          showImgUrl={configData.MidJourney.show_img_url}
          setShowImgUrl={(value) => updateConfigData("MidJourney", "show_img_url", value)}
          dailyLimit={configData.MidJourney.daily_limit}
          setDailyLimit={(value) => updateConfigData("MidJourney", "daily_limit", value)}
          imaginePrefix={configData.MidJourney.imagine_prefix}
          setImaginePrefix={(value) => updateConfigData("MidJourney", "imagine_prefix", value)}
          fetchPrefix={configData.MidJourney.fetch_prefix}
          setFetchPrefix={(value) => updateConfigData("MidJourney", "fetch_prefix", value)}
          upPrefix={configData.MidJourney.up_prefix}
          setUpPrefix={(value) => updateConfigData("MidJourney", "up_prefix", value)}
          padPrefix={configData.MidJourney.pad_prefix}
          setPadPrefix={(value) => updateConfigData("MidJourney", "pad_prefix", value)}
          blendPrefix={configData.MidJourney.blend_prefix}
          setBlendPrefix={(value) => updateConfigData("MidJourney", "blend_prefix", value)}
          describePrefix={configData.MidJourney.describe_prefix}
          setDescribePrefix={(value) => updateConfigData("MidJourney", "describe_prefix", value)}
          queuePrefix={configData.MidJourney.queue_prefix}
          setQueuePrefix={(value) => updateConfigData("MidJourney", "queue_prefix", value)}
          endPrefix={configData.MidJourney.end_prefix}
          setEndPrefix={(value) => updateConfigData("MidJourney", "end_prefix", value)}
          rerollPrefix={configData.MidJourney.reroll_prefix}
          setRerollPrefix={(value) => updateConfigData("MidJourney", "reroll_prefix", value)}
        />
        <Divider orientation="left">
          <div id="cclite">Cclite</div>
        </Divider>
        <CcliteConfig
          alapiKey={configData.cclite.alapi_key}
          setAlapiKey={(value) => updateConfigData("cclite", "alapi_key", value)}
          bingSubscriptionKey={configData.cclite.bing_subscription_key}
          setBingSubscriptionKey={(value) => updateConfigData("cclite", "bing_subscription_key", value)}
          openAiApiKey={configData.cclite.open_ai_api_key}
          setOpenAiApiKey={(value) => updateConfigData("cclite", "open_ai_api_key", value)}
          openAiApiBase={configData.cclite.open_ai_api_base}
          setOpenAiApiBase={(value) => updateConfigData("cclite", "open_ai_api_base", value)}
          functionsOpenaiModel={configData.cclite.functions_openai_model}
          setFunctionsOpenaiModel={(value) => updateConfigData("cclite", "functions_openai_model", value)}
          assistantOpenaiModel={configData.cclite.assistant_openai_model}
          setAssistantOpenaiModel={(value) => updateConfigData("cclite", "assistant_openai_model", value)}
          getwtKey={configData.cclite.getwt_key}
          setGetwtKey={(value) => updateConfigData("cclite", "getwt_key", value)}
          googleCxId={configData.cclite.google_cx_id}
          setGoogleCxId={(value) => updateConfigData("cclite", "google_cx_id", value)}
          googleApiKey={configData.cclite.google_api_key}
          setGoogleApiKey={(value) => updateConfigData("cclite", "google_api_key", value)}
          // ccApiBase={configData.cclite.cc_api_base}
          // setCcApiBase={(value) => updateConfigData("cclite", "cc_api_base", value)}
          // prompt={configData.cclite.prompt}
          // setPrompt={(key, value) => updateConfigDataL3("cclite", "prompt", key, value)}
        />
        <Divider orientation="left">
          <div id="sum4all">Sum4all</div>
        </Divider>
        <Sum4allConfig
          sumService={configData.sum4all.sum_service}
          setSumService={(value) => updateConfigData("sum4all", "sum_service", value)}
          fileSum={configData.sum4all.file_sum}
          setFileSum={(value) => updateConfigData("sum4all", "file_sum", value)}
          searchService={configData.sum4all.search_service}
          setSearchService={(value) => updateConfigData("sum4all", "search_service", value)}
          bibigptKey={configData.sum4all.bibigpt_key}
          setBibigptKey={(value) => updateConfigData("sum4all", "bibigpt_key", value)}
          sum4allKey={configData.sum4all.sum4all_key}
          setSum4allKey={(value) => updateConfigData("sum4all", "sum4all_key", value)}
          perplexityKey={configData.sum4all.perplexity_key}
          setPerplexityKey={(value) => updateConfigData("sum4all", "perplexity_key", value)}
          // outputLanguage={configData.sum4all.outputLanguage}
          // setOutputLanguage={(value) => updateConfigData("sum4all", "outputLanguage", value)}
          groupSharing={configData.sum4all.group_sharing}
          setGroupSharing={(value) => updateConfigData("sum4all", "group_sharing", value)}
          opensumKey={configData.sum4all.opensum_key}
          setOpensumKey={(value) => updateConfigData("sum4all", "opensum_key", value)}
          openAiApiKey={configData.sum4all.open_ai_api_key}
          setOpenAiApiKey={(value) => updateConfigData("sum4all", "open_ai_api_key", value)}
          model={configData.sum4all.model}
          setModel={(value) => updateConfigData("sum4all", "model", value)}
          openAiApiBase={configData.sum4all.open_ai_api_base}
          setOpenAiApiBase={(value) => updateConfigData("sum4all", "open_ai_api_base", value)}
          prompt={configData.sum4all.prompt}
          setPrompt={(value) => updateConfigData("sum4all", "prompt", value)}
          searchPrompt={configData.sum4all.search_prompt}
          setSearchPrompt={(value) => updateConfigData("sum4all", "search_prompt", value)}
          imageService={configData.sum4all.image_service}
          setImageService={(value) => updateConfigData("sum4all", "image_service", value)}
          qaPrefix={configData.sum4all.qa_prefix}
          setQaPrefix={(value) => updateConfigData("sum4all", "qa_prefix", value)}
          searchPrefix={configData.sum4all.search_prefix}
          setSearchPrefix={(value) => updateConfigData("sum4all", "search_prefix", value)}
          xunfeiAppId={configData.sum4all.xunfei_app_id}
          setXunfeiAppId={(value) => updateConfigData("sum4all", "xunfei_app_id", value)}
          xunfeiApiKey={configData.sum4all.xunfei_api_key}
          setXunfeiApiKey={(value) => updateConfigData("sum4all", "xunfei_api_key", value)}
          xunfeiApiSecret={configData.sum4all.xunfei_api_secret}
          setXunfeiApiSecret={(value) => updateConfigData("sum4all", "xunfei_api_secret", value)}
        />

        <br />
        <br />
        <br />

        {/* <Form.Item {...buttonItemLayout}> */}
        <Button type="primary" htmlType="submit" className="config-form-button floating-button" style={buttonStyle}>
          保存配置并重载插件
        </Button>
        {/* </Form.Item> */}
      </Form>
    </div>
  );
};

export default FormPlugins;
