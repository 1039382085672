import { Divider, Form, Input, InputNumber, Switch } from "antd";
import React from "react";
import EditableTagGroup from "./EditableTagGroup";
import { handleChange, handleSwitchChange } from "./Handlers";

interface ConfigProps {
  imageCreatePrefix: string[];
  setImageCreatePrefix: (value: string[]) => void;
  useDalle3: boolean;
  setUseDalle3: (value: boolean) => void;
  dalle3ApiKey: string;
  setDalle3ApiKey: (value: string) => void;
  dalle3ApiBase: string;
  setDalle3ApiBase: (value: string) => void;
  rateLimitDalle: number;
  setRateLimitDalle: (value: number) => void;
  dailyLimitDalle: number;
  setDailyLimitDalle: (value: number) => void;
}

const ImageConfig: React.FC<ConfigProps> = ({
  imageCreatePrefix,
  setImageCreatePrefix,
  useDalle3,
  setUseDalle3,
  dalle3ApiKey,
  setDalle3ApiKey,
  dalle3ApiBase,
  setDalle3ApiBase,
  rateLimitDalle,
  setRateLimitDalle,
  dailyLimitDalle,
  setDailyLimitDalle,
}) => {
  return (
    <>
      <Divider orientation="left">
        <div id="image">画图配置</div>
      </Divider>
      <Form.Item label="画图前缀">
        <EditableTagGroup tags={imageCreatePrefix} setTags={setImageCreatePrefix} />
        <p className="comment">开启图片回复的前缀</p>
      </Form.Item>

      <Form.Item label="使用Dalle-3接口画图">
        <Switch checked={useDalle3} onChange={handleSwitchChange(setUseDalle3)} />
      </Form.Item>
      {useDalle3 && (
        <>
          <Form.Item label="Dalle-3 API Key">
            <Input
              type="text"
              value={dalle3ApiKey}
              onChange={handleChange(setDalle3ApiKey)}
              placeholder="不填写则默认使用聊天API Key"
            />
          </Form.Item>
          <Form.Item label="Dalle-3 API Base">
            <Input
              type="text"
              value={dalle3ApiBase}
              onChange={handleChange(setDalle3ApiBase)}
              placeholder="不填写则默认使用https://api.openai.com/v1"
            />
          </Form.Item>

          <Form.Item label="Dalle-3并发限制">
            <InputNumber
              min={1}
              value={rateLimitDalle}
              onChange={(value: number | null) => {
                setRateLimitDalle(value!);
              }}
            />
            <p className="comment">官方API限速每分钟1张</p>
          </Form.Item>

          <Form.Item label="Dalle-3每日限额">
            <InputNumber
              min={1}
              value={dailyLimitDalle}
              onChange={(value: number | null) => {
                setDailyLimitDalle(value!);
              }}
            />
            <p className="comment">限制每天一个账号可以使用Dalle3画图的次数</p>
          </Form.Item>
        </>
      )}
    </>
  );
};

export default ImageConfig;
