import { Form, Input, Radio, Switch, Typography } from "antd";
import React from "react";
import { availOpenAiModel, handleAreaChange, handleChange, handleRadioChange, handleSwitchChange } from "./Handlers";

interface Sum4allConfigProps {
  sumService: string;
  setSumService: (value: string) => void;
  fileSum: boolean;
  setFileSum: (value: boolean) => void;
  searchService: string;
  setSearchService: (value: string) => void;
  bibigptKey: string;
  setBibigptKey: (value: string) => void;
  sum4allKey: string;
  setSum4allKey: (value: string) => void;
  perplexityKey: string;
  setPerplexityKey: (value: string) => void;
  // outputLanguage: string;
  // setOutputLanguage: (value: string) => void;
  groupSharing: boolean;
  setGroupSharing: (value: boolean) => void;
  opensumKey: string;
  setOpensumKey: (value: string) => void;
  openAiApiKey: string;
  setOpenAiApiKey: (value: string) => void;
  model: string;
  setModel: (value: string) => void;
  openAiApiBase: string;
  setOpenAiApiBase: (value: string) => void;
  prompt: string;
  setPrompt: (value: string) => void;
  searchPrompt: string;
  setSearchPrompt: (value: string) => void;
  qaPrefix: string;
  setQaPrefix: (value: string) => void;
  searchPrefix: string;
  setSearchPrefix: (value: string) => void;
  imageService: string;
  setImageService: (value: string) => void;
  xunfeiAppId: string;
  setXunfeiAppId: (value: string) => void;
  xunfeiApiKey: string;
  setXunfeiApiKey: (value: string) => void;
  xunfeiApiSecret: string;
  setXunfeiApiSecret: (value: string) => void;
}

const availSumService = ["关闭", "openai", "sum4all", "bibigpt", "opensum"];
const availImageSumService = ["关闭", "openai", "xunfei"];
const availSearchService = ["关闭", "sum4all", "perplexity"];
// const availBibigptOutputLanguage = {
//   "zh-CN": "中文",
//   "en-US": "English",
//   "zh-TW": "繁體中文",
//   "ja-JP": "日本語",
//   "it-IT": "Italiano",
//   "de-DE": "Deutsch",
//   "es-ES": "Español",
//   "fr-FR": "Français",
//   "nl-NL": "Nederlands",
//   "ko-KR": "한국어",
//   "km-KH": "ភាសាខ្មែរ",
//   "hi-IN": "हिंदी",
// };

const Sum4allConfig: React.FC<Sum4allConfigProps> = ({
  sumService,
  setSumService,
  fileSum,
  setFileSum,
  searchService,
  setSearchService,
  bibigptKey,
  setBibigptKey,
  sum4allKey,
  setSum4allKey,
  perplexityKey,
  setPerplexityKey,
  // outputLanguage,
  // setOutputLanguage,
  groupSharing,
  setGroupSharing,
  opensumKey,
  setOpensumKey,
  openAiApiKey,
  setOpenAiApiKey,
  model,
  setModel,
  openAiApiBase,
  setOpenAiApiBase,
  prompt,
  setPrompt,
  searchPrompt,
  setSearchPrompt,
  qaPrefix,
  setQaPrefix,
  searchPrefix,
  setSearchPrefix,
  imageService,
  setImageService,
  xunfeiAppId,
  setXunfeiAppId,
  xunfeiApiKey,
  setXunfeiApiKey,
  xunfeiApiSecret,
  setXunfeiApiSecret,
}) => {
  return (
    <>
      <Form.Item label="内容总结服务">
        <Radio.Group value={sumService} onChange={handleRadioChange(setSumService)}>
          {availSumService.map((m) => (
            <Radio key={m} value={m}>
              {m}
            </Radio>
          ))}
        </Radio.Group>
        <p className="comment">选择openai或sum4all可以总结文件内容</p>
      </Form.Item>

      {(sumService === "openai" || sumService === "sum4all") && (
        <Form.Item label="文件总结">
          <Switch checked={fileSum} onChange={handleSwitchChange(setFileSum)} />
        </Form.Item>
      )}

      <Form.Item label="图片总结服务">
        <Radio.Group value={imageService} onChange={handleRadioChange(setImageService)}>
          {availImageSumService.map((m) => (
            <Radio key={m} value={m}>
              {m}
            </Radio>
          ))}
        </Radio.Group>
        <p className="comment">如果开启此功能，则无法使用mj插件的识图和垫图功能</p>
      </Form.Item>

      <Form.Item label="搜索服务">
        <Radio.Group value={searchService} onChange={handleRadioChange(setSearchService)}>
          {availSearchService.map((m) => (
            <Radio key={m} value={m}>
              {m}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item label="群聊链接卡片">
        <Switch checked={groupSharing} onChange={handleSwitchChange(setGroupSharing)} />
        <p className="comment">是否支持群聊内的链接卡片</p>
      </Form.Item>

      <Form.Item label="追问触发词">
        <Input type="text" value={qaPrefix} onChange={handleChange(setQaPrefix)} />
        <p className="comment">以该词开头，才能触发追问</p>
      </Form.Item>
      <Form.Item label="搜索触发词">
        <Input type="text" value={searchPrefix} onChange={handleChange(setSearchPrefix)} />
        <p className="comment">以该词开头，才能触发搜索</p>
      </Form.Item>

      {imageService === "xunfei" && (
        <>
          <Form.Item label="讯飞应用ID">
            <Input type="text" value={xunfeiAppId} onChange={handleChange(setXunfeiAppId)} />
          </Form.Item>
          <Form.Item label="讯飞API Secret">
            <Input type="text" value={xunfeiApiSecret} onChange={handleChange(setXunfeiApiSecret)} />
          </Form.Item>
          <Form.Item label="讯飞API Key">
            <Input type="text" value={xunfeiApiKey} onChange={handleChange(setXunfeiApiKey)} />
          </Form.Item>
        </>
      )}

      {(sumService === "openai" || imageService === "openai") && (
        <>
          <Form.Item label="OpenAI API Key">
            <Input
              type="text"
              value={openAiApiKey}
              onChange={handleChange(setOpenAiApiKey)}
              placeholder="使用插件必填"
            />
          </Form.Item>

          <Form.Item label="OpenAI API Base">
            <Input
              type="text"
              value={openAiApiBase}
              onChange={handleChange(setOpenAiApiBase)}
              placeholder="不填写则默认使用https://api.openai.com/v1"
            />
          </Form.Item>

          <Form.Item label="ChatGPT模型">
            <Radio.Group value={model} onChange={handleRadioChange(setModel)}>
              {availOpenAiModel.map((value) => (
                <Radio key={value} value={value}>
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </>
      )}

      {(searchService === "sum4all" || sumService === "sum4all") && (
        <Form.Item label="sum4all key">
          <Input type="text" value={sum4allKey} onChange={handleChange(setSum4allKey)} />
          <p className="comment">
            点击
            <Typography.Link href="https://pro.sum4all.site/login" target="_blank">
              这里
            </Typography.Link>
            申请
          </p>
        </Form.Item>
      )}

      {sumService === "opensum" && (
        <Form.Item label="opensum key">
          <Input type="text" value={opensumKey} onChange={handleChange(setOpensumKey)} />
          {/* <p className="comment">
            点击
            <Typography.Link href="https://console.qweather.com/#/apps" target="_blank">
              这里
            </Typography.Link>
            申请
          </p> */}
        </Form.Item>
      )}

      {searchService === "perplexity" && (
        <Form.Item label="perplexity key">
          <Input type="text" value={perplexityKey} onChange={handleChange(setPerplexityKey)} />
          {/* <p className="comment">
            点击
            <Typography.Link href="https://console.qweather.com/#/apps" target="_blank">
              这里
            </Typography.Link>
            申请
          </p> */}
        </Form.Item>
      )}

      {sumService === "bibigpt" && (
        <Form.Item label="bibigpt key">
          <Input type="text" value={bibigptKey} onChange={handleChange(setBibigptKey)} />
          <p className="comment">
            点击
            <Typography.Link href="https://console.qweather.com/#/apps" target="_blank">
              这里
            </Typography.Link>
            申请
          </p>
        </Form.Item>
      )}

      {/* <Form.Item label="bibigpt的输出语言">
        <Radio.Group value={outputLanguage} onChange={handleRadioChange(setOutputLanguage)}>
          {Object.keys(availBibigptOutputLanguage).map((key) => (
            <Radio key={key} value={key}>
              {availBibigptOutputLanguage[key as keyof typeof availBibigptOutputLanguage]}: {key}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item> */}

      <Form.Item label="总结内容提示词">
        <Input.TextArea
          value={prompt}
          onChange={handleAreaChange(setPrompt)}
          autoSize={{ minRows: 2, maxRows: 4 }}
          showCount={true}
        />
      </Form.Item>

      <Form.Item label="总结搜索提示词">
        <Input.TextArea
          value={searchPrompt}
          onChange={handleAreaChange(setSearchPrompt)}
          autoSize={{ minRows: 2, maxRows: 4 }}
          showCount={true}
        />
      </Form.Item>
    </>
  );
};

export default Sum4allConfig;
