import { Form, Divider, Input } from "antd";
import React from "react";
import EditableTagGroup from "./EditableTagGroup";
import { handleChange } from "./Handlers";
interface ConfigProps {
  singleChatPrefix: string[];
  setSingleChatPrefix: (value: string[]) => void;
  singleChatReplyPrefix: string;
  setSingleChatReplyPrefix: (value: string) => void;
  singleChatReplySuffix: string;
  setSingleChatReplySuffix: (value: string) => void;
}

const SingleChatConfig: React.FC<ConfigProps> = ({
  singleChatPrefix,
  setSingleChatPrefix,
  singleChatReplyPrefix,
  setSingleChatReplyPrefix,
  singleChatReplySuffix,
  setSingleChatReplySuffix,
}) => {
  return (
    <>
      <Divider orientation="left">
        <div id="single">私聊配置</div>
      </Divider>
      <Form.Item label="私聊前缀">
        <EditableTagGroup tags={singleChatPrefix} setTags={setSingleChatPrefix} />
        <p className="comment">私聊时文本需要包含该前缀才能触发机器人回复，全部删除可直接与机器人聊天</p>
      </Form.Item>
      <Form.Item label="私聊回复前缀">
        <Input
          type="text"
          value={singleChatReplyPrefix}
          onChange={handleChange(setSingleChatReplyPrefix)}
          placeholder="[bot] "
        />
        <p className="comment">私聊时自动回复的前缀，用于区分真人</p>
      </Form.Item>
      <Form.Item label="私聊回复后缀">
        <Input type="text" value={singleChatReplySuffix} onChange={handleChange(setSingleChatReplySuffix)} />
        <p className="comment">私聊时自动回复的后缀，\n 可以换行</p>
      </Form.Item>
    </>
  );
};

export default SingleChatConfig;
