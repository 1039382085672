import { RadioChangeEvent } from "antd";
import { ChangeEvent } from "react";

export const availService = { openai: "ChatGPT", wenxin: "文心一言", xunfei: "讯飞星火", gemini: "Google Gemini" };

export const availOpenAiModel = [
  "gpt-3.5-turbo",
  "gpt-3.5-turbo-0613",
  "gpt-3.5-turbo-16k",
  "gpt-3.5-turbo-1106",
  "gpt-4",
  "gpt-4-32k",
  "gpt-4-1106-preview",
  "gpt-4-vision-preview",
];

export const handleChange = (setter: (value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
  setter(event.target.value);
};

export const handleAreaChange = (setter: (value: string) => void) => (event: ChangeEvent<HTMLTextAreaElement>) => {
  setter(event.target.value);
};

export const handleRadioChange = (setter: (value: string) => void) => (event: RadioChangeEvent) => {
  setter(event.target.value);
};

export const handleSwitchChange = (setter: (checked: boolean) => void) => (checked: boolean) => {
  setter(checked);
};
