import { Form, Divider, Input, Switch } from "antd";
import React from "react";
import EditableTagGroup from "./EditableTagGroup";
import { handleChange, handleAreaChange, handleSwitchChange } from "./Handlers";
interface ConfigProps {
  groupChatPrefix: string[];
  setGroupChatPrefix: (value: string[]) => void;
  groupChatReplyPrefix: string;
  setGroupChatReplyPrefix: (value: string) => void;
  groupChatReplySuffix: string;
  setGroupChatReplySuffix: (value: string) => void;
  groupChatKeyword: string[];
  setGroupChatKeyword: (value: string[]) => void;
  groupAtOff: boolean;
  setGroupAtOff: (value: boolean) => void;
  groupNameWhiteList: string[];
  setGroupNameWhiteList: (value: string[]) => void;
  groupChatInOneSession: string[];
  setGroupChatInOneSession: (value: string[]) => void;
  groupWelcomeMsg: string;
  setGroupWelcomeMsg: (value: string) => void;
}

const GroupChatConfig: React.FC<ConfigProps> = ({
  groupChatPrefix,
  setGroupChatPrefix,
  groupChatReplyPrefix,
  setGroupChatReplyPrefix,
  groupChatReplySuffix,
  setGroupChatReplySuffix,
  groupChatKeyword,
  setGroupChatKeyword,
  groupAtOff,
  setGroupAtOff,
  groupNameWhiteList,
  setGroupNameWhiteList,
  groupChatInOneSession,
  setGroupChatInOneSession,
  groupWelcomeMsg,
  setGroupWelcomeMsg,
}) => {
  return (
    <>
      <Divider orientation="left">
        <div id="group">群聊配置</div>{" "}
      </Divider>
      <Form.Item label="群聊前缀">
        <EditableTagGroup tags={groupChatPrefix} setTags={setGroupChatPrefix} />
        <p className="comment">群聊时包含该前缀则会触发机器人回复</p>
      </Form.Item>
      <Form.Item label="群聊回复前缀">
        <Input type="text" value={groupChatReplyPrefix} onChange={handleChange(setGroupChatReplyPrefix)} />
        <p className="comment">群聊时自动回复的前缀</p>
      </Form.Item>
      <Form.Item label="群聊回复后缀">
        <Input type="text" value={groupChatReplySuffix} onChange={handleChange(setGroupChatReplySuffix)} />
        <p className="comment">群聊时自动回复的后缀，\n 可以换行</p>
      </Form.Item>
      <Form.Item label="群聊触发关键词">
        <EditableTagGroup tags={groupChatKeyword} setTags={setGroupChatKeyword} />
        <p className="comment">群聊时包含该关键词则会触发机器人回复</p>
      </Form.Item>
      <Form.Item label="关闭群聊时@bot的触发">
        <Switch checked={groupAtOff} onChange={handleSwitchChange(setGroupAtOff)} />
      </Form.Item>

      <Form.Item label="自动回复群列表">
        <EditableTagGroup tags={groupNameWhiteList} setTags={setGroupNameWhiteList} />
        <p className="comment">开启自动回复的群名称列表，输入ALL_GROUP表示所有群</p>
      </Form.Item>
      <Form.Item label="上下文共享群列表">
        <EditableTagGroup tags={groupChatInOneSession} setTags={setGroupChatInOneSession} />
        <p className="comment">支持会话上下文共享的群名称列表，输入ALL_GROUP表示所有群</p>
      </Form.Item>

      <Form.Item label="进群欢迎语">
        <Input.TextArea
          value={groupWelcomeMsg}
          onChange={handleAreaChange(setGroupWelcomeMsg)}
          placeholder="配置新人进群固定欢迎语，不配置则使用随机风格欢迎"
          showCount={true}
          autoSize={{ minRows: 2, maxRows: 4 }}
        />
      </Form.Item>
    </>
  );
};

export default GroupChatConfig;
