import { Form, Input, Radio, Switch, Divider, InputNumber } from "antd";
import React from "react";
import { availOpenAiModel, availService, handleChange, handleRadioChange, handleSwitchChange } from "./Handlers";
interface ApiConfigProps {
  apiKey: string;
  setApiKey: (value: string) => void;
  apiBase: string;
  setApiBase: (value: string) => void;
  service: string;
  setService: (value: string) => void;
  model: string;
  setModel: (value: string) => void;
  // proxy: string;
  // setProxy: (value: string) => void;
  useAzureChatGPT: boolean;
  setUseAzureChatGPT: (value: boolean) => void;
  azureDeploymentId: string;
  setAzureDeploymentId: (value: string) => void;
  azureApiVersion: string;
  setAzureApiVersion: (value: string) => void;
  rateLimitChatGPT: number;
  setRateLimitChatGPT: (value: number) => void;

  geminiApiKey: string;
  setGeminiApiKey: (value: string) => void;

  baiduWenxinModel: string;
  setBaiduWenxinModel: (value: string) => void;
  baiduWenxinApiKey: string;
  setBaiduWenxinApiKey: (value: string) => void;
  baiduWenxinSecretKey: string;
  setBaiduWenxinSecretKey: (value: string) => void;

  xunfeiAppId: string;
  setXunfeiAppId: (value: string) => void;
  xunfeiApiKey: string;
  setXunfeiApiKey: (value: string) => void;
  xunfeiApiSecret: string;
  setXunfeiApiSecret: (value: string) => void;
}

const azureApiVersions = [
  "2023-03-15-preview",
  "2023-05-15",
  "2023-06-01-preview",
  "2023-07-01-preview",
  "2023-08-01-preview",
];

const ApiConfig: React.FC<ApiConfigProps> = ({
  apiKey,
  setApiKey,
  apiBase,
  setApiBase,
  service,
  setService,
  model,
  setModel,
  // proxy,
  // setProxy,

  useAzureChatGPT,
  setUseAzureChatGPT,
  azureDeploymentId,
  setAzureDeploymentId,
  azureApiVersion,
  setAzureApiVersion,

  rateLimitChatGPT,
  setRateLimitChatGPT,

  geminiApiKey,
  setGeminiApiKey,

  baiduWenxinModel,
  setBaiduWenxinModel,
  baiduWenxinApiKey,
  setBaiduWenxinApiKey,
  baiduWenxinSecretKey,
  setBaiduWenxinSecretKey,

  xunfeiAppId,
  setXunfeiAppId,
  xunfeiApiKey,
  setXunfeiApiKey,
  xunfeiApiSecret,
  setXunfeiApiSecret,
}) => {
  return (
    <>
      <Divider orientation="left">
        <div id="model">AI大模型</div>
      </Divider>
      <Form.Item label="选择AI服务">
        <Radio.Group value={service} onChange={handleRadioChange(setService)}>
          {Object.keys(availService).map((key) => (
            <Radio key={key} value={key}>
              {availService[key as keyof typeof availService]}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      {service === "wenxin" && (
        <>
          <Form.Item label="文心模型">
            <Input type="text" value={baiduWenxinModel} onChange={handleChange(setBaiduWenxinModel)} />
          </Form.Item>
          <Form.Item label="文心API Key">
            <Input type="text" value={baiduWenxinApiKey} onChange={handleChange(setBaiduWenxinApiKey)} />
          </Form.Item>
          <Form.Item label="文心Secret Key">
            <Input type="text" value={baiduWenxinSecretKey} onChange={handleChange(setBaiduWenxinSecretKey)} />
          </Form.Item>
        </>
      )}

      {service === "xunfei" && (
        <>
          <Form.Item label="讯飞应用ID">
            <Input type="text" value={xunfeiAppId} onChange={handleChange(setXunfeiAppId)} />
          </Form.Item>
          <Form.Item label="讯飞API Secret">
            <Input type="text" value={xunfeiApiSecret} onChange={handleChange(setXunfeiApiSecret)} />
          </Form.Item>
          <Form.Item label="讯飞API Key">
            <Input type="text" value={xunfeiApiKey} onChange={handleChange(setXunfeiApiKey)} />
          </Form.Item>
        </>
      )}

      {service === "gemini" && (
        <>
          <Form.Item label="Google Gemini API Key">
            <Input type="text" value={geminiApiKey} onChange={handleChange(setGeminiApiKey)} />
          </Form.Item>
        </>
      )}

      {service === "openai" && (
        <>
          <Form.Item label="ChatGPT模型">
            <Radio.Group value={model} onChange={handleRadioChange(setModel)}>
              {availOpenAiModel.map((value) => (
                <Radio key={value} value={value}>
                  {value}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>

          <Form.Item label="API Key">
            <Input type="text" value={apiKey} onChange={handleChange(setApiKey)} />
          </Form.Item>
          <Form.Item label="API Base">
            <Input
              type="text"
              value={apiBase}
              onChange={handleChange(setApiBase)}
              placeholder="不填写则默认使用https://api.openai.com/v1"
            />
          </Form.Item>

          <Form.Item label="使用Azure">
            <Switch checked={useAzureChatGPT} onChange={handleSwitchChange(setUseAzureChatGPT)} />
          </Form.Item>
          {useAzureChatGPT && (
            <>
              <Form.Item label="Azure Deployment ID">
                <Input
                  type="text"
                  value={azureDeploymentId}
                  onChange={handleChange(setAzureDeploymentId)}
                  placeholder="Your Azure Deployment ID"
                />
              </Form.Item>
              <Form.Item label="Azure API Version">
                <Radio.Group value={azureApiVersion} onChange={handleRadioChange(setAzureApiVersion)}>
                  {azureApiVersions.map((m) => (
                    <Radio key={m} value={m}>
                      {m}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </>
          )}
        </>
      )}

      {/* <Form.Item label="代理">
        <Input type="text" value={proxy} onChange={handleChange(setProxy)} placeholder="IP:Port (127.0.0.1:7890)" />
      </Form.Item> */}

      <Form.Item label="并发限制">
        <InputNumber
          min={1}
          value={rateLimitChatGPT}
          onChange={(value: number | null) => {
            setRateLimitChatGPT(value!);
          }}
        />
        <p className="comment">每分钟限制多少次访问</p>
      </Form.Item>
    </>
  );
};

export default ApiConfig;
