export function getApiUrl(path: string): string {
  if (process.env.NODE_ENV === "development") {
    const currentURL = new URL(window.location.href); // 获取当前页面的URL
    const protocol = currentURL.protocol;
    const hostname = currentURL.hostname;
    const port = 8801;
    const apiURL = new URL(path, `${protocol}//${hostname}:${port}`);
    return apiURL.href;
  }
  return path;
}

export const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};
