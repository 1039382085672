import React, { useState } from "react";
import { AppstoreOutlined, SettingOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Menu, Typography } from "antd";
import QRCodeComponent from "./components/QRCodeComponent";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  path: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label: path ? <Typography.Link href={path}>{label}</Typography.Link> : label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("通用配置", "sub1", "#general", <SettingOutlined />, [
    getItem("AI大模型", "11", "#model"),
    getItem("聊天配置", "12", "#chat"),
    getItem("画图配置", "13", "#image"),
    getItem("语音配置", "14", "#voice"),
    getItem("私聊配置", "15", "#single"),
    getItem("群聊配置", "16", "#group"),
  ]),
  getItem("插件配置", "sub2", "#plugin", <AppstoreOutlined />, [
    getItem("Godcmd管理", "21", "#godcmd"),
    getItem("Midjourney代理", "22", "#midjourney"),
    getItem("Cclite", "23", "#cclite"),
    getItem("Sum4all", "24", "#sum4all"),
  ]),
];

// submenu keys of first level
const rootSubmenuKeys = ["sub1", "sub2"];

const Sidebar: React.FC = () => {
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <QRCodeComponent />
      <Menu mode="inline" openKeys={openKeys} onOpenChange={onOpenChange} style={{ width: 256 }} items={items} />;
    </>
  );
};

export default Sidebar;
