import { Col, Divider, Form, Input, InputNumber, Row, Slider, Switch } from "antd";
import React from "react";
import { handleAreaChange, handleSwitchChange } from "./Handlers";

interface ConfigProps {
  characterDesc: string;
  setCharacterDesc: (value: string) => void;
  subscribeMsg: string;
  setSubscribeMsg: (value: string) => void;
  conversationMaxTokens: number;
  setConversationMaxTokens: (value: number) => void;
  hotReload: boolean;
  setHotReload: (value: boolean) => void;
  // appDataDir: string;
  // setAppDataDir: (value: string) => void;
  triggerBySelf: boolean;
  setTriggerBySelf: (trigger_by_self: boolean) => void;
  debug: boolean;
  setDebug: (debug: boolean) => void;
}

const ChatConfig: React.FC<ConfigProps> = ({
  characterDesc,
  setCharacterDesc,
  subscribeMsg,
  setSubscribeMsg,
  conversationMaxTokens,
  setConversationMaxTokens,
  hotReload,
  setHotReload,
  // appDataDir,
  // setAppDataDir,

  triggerBySelf,
  setTriggerBySelf,
  debug,
  setDebug,
}) => {
  const handleSliderChange = (newValue: number | null) => {
    setConversationMaxTokens(typeof newValue === "number" ? newValue : 1000);
  };

  return (
    <>
      <Divider orientation="left">
        <div id="chat">聊天配置</div>
      </Divider>

      <Form.Item label="人格描述">
        <Input.TextArea
          value={characterDesc}
          onChange={handleAreaChange(setCharacterDesc)}
          placeholder="Your character description"
          autoSize={{ minRows: 2, maxRows: 4 }}
          showCount={true}
        />
      </Form.Item>

      <Form.Item label="上下文限制">
        <Row>
          <Col span={12}>
            <Slider
              min={100}
              max={32000}
              step={100}
              onChange={handleSliderChange}
              value={typeof conversationMaxTokens === "number" ? conversationMaxTokens : 1000}
            />
          </Col>
          <Col span={4}>
            <InputNumber
              min={100}
              max={32000}
              style={{ margin: "0 16px" }}
              value={conversationMaxTokens}
              onChange={handleSliderChange}
            />
          </Col>
        </Row>
        <p className="comment">支持上下文记忆的最多字符数</p>
      </Form.Item>

      <Form.Item label="订阅消息">
        <Input.TextArea
          value={subscribeMsg}
          onChange={handleAreaChange(setSubscribeMsg)}
          placeholder="Your subscription message"
          showCount={true}
          autoSize={{ minRows: 2, maxRows: 4 }}
        />
      </Form.Item>

      <Form.Item label="开启热重载">
        <Switch checked={hotReload} onChange={handleSwitchChange(setHotReload)} />
      </Form.Item>

      {/* <Form.Item label="bot数据子目录">
        <Input
          type="text"
          value={appDataDir}
          onChange={handleChange(setAppDataDir)}
        />
        <p className="comment">配置好后不要修改</p>
      </Form.Item> */}

      <Form.Item label="自触发">
        <Switch checked={triggerBySelf} onChange={handleSwitchChange(setTriggerBySelf)} />
        <p>是否允许机器人账号自问自答，主要调试用</p>
      </Form.Item>

      <Form.Item label="debug模式">
        <Switch checked={debug} onChange={handleSwitchChange(setDebug)} />
        <p>开启后会打印更多日志，主要调试用</p>
      </Form.Item>
    </>
  );
};

export default ChatConfig;
