import { Form, Input, Radio, Typography } from "antd";
import React from "react";
import { availOpenAiModel, handleChange, handleRadioChange } from "./Handlers";

interface CcliteConfigProps {
  alapiKey: string;
  setAlapiKey: (value: string) => void;
  bingSubscriptionKey: string;
  setBingSubscriptionKey: (value: string) => void;
  openAiApiKey: string;
  setOpenAiApiKey: (value: string) => void;
  openAiApiBase: string;
  setOpenAiApiBase: (value: string) => void;
  functionsOpenaiModel: string;
  setFunctionsOpenaiModel: (value: string) => void;
  assistantOpenaiModel: string;
  setAssistantOpenaiModel: (value: string) => void;
  getwtKey: string;
  setGetwtKey: (value: string) => void;
  googleApiKey: string;
  setGoogleApiKey: (value: string) => void;
  googleCxId: string;
  setGoogleCxId: (value: string) => void;
  // ccApiBase: string;
  // setCcApiBase: (value: string) => void;
  // prompt: { [key: string]: string };
  // setPrompt: (key: string, value: string) => void;
}

const CcliteConfig: React.FC<CcliteConfigProps> = ({
  alapiKey,
  setAlapiKey,
  bingSubscriptionKey,
  setBingSubscriptionKey,
  openAiApiKey,
  setOpenAiApiKey,
  openAiApiBase,
  setOpenAiApiBase,
  functionsOpenaiModel,
  setFunctionsOpenaiModel,
  assistantOpenaiModel,
  setAssistantOpenaiModel,
  getwtKey,
  setGetwtKey,
  googleApiKey,
  setGoogleApiKey,
  googleCxId,
  setGoogleCxId,
  // ccApiBase,
  // setCcApiBase,
  // prompt,
  // setPrompt,
}) => {
  // const updatePrompt = (keyL1: string, value: string) => {
  //   setPrompt(keyL1, value);
  // };

  return (
    <>
      <Form.Item label="早报Alapi Key">
        <Input type="text" value={alapiKey} onChange={handleChange(setAlapiKey)} />
        <p className="comment">
          点击
          <Typography.Link href="https://admin.alapi.cn/api_manager/token_manager" target="_blank">
            这里
          </Typography.Link>
          申请
        </p>
      </Form.Item>

      <Form.Item label="Bing搜索Key">
        <Input type="text" value={bingSubscriptionKey} onChange={handleChange(setBingSubscriptionKey)} />
        <p className="comment">
          点击
          <Typography.Link href="https://www.microsoft.com/en-us/bing/apis/bing-web-search-api" target="_blank">
            这里
          </Typography.Link>
          申请
        </p>
      </Form.Item>

      <Form.Item label="Google搜索cx">
        <Input type="text" value={googleCxId} onChange={handleChange(setGoogleCxId)} />
        <p className="comment">
          点击
          <Typography.Link
            href="https://programmablesearchengine.google.com/controlpanel/all?hl=zh-cn"
            target="_blank">
            这里
          </Typography.Link>
          申请
        </p>
      </Form.Item>

      <Form.Item label="Google搜索Key">
        <Input type="text" value={googleApiKey} onChange={handleChange(setGoogleApiKey)} />
        <p className="comment">
          点击
          <Typography.Link
            href="https://developers.google.com/custom-search/v1/introduction?apix=true&hl=zh-cn#identify_your_application_to_google_with_api_key"
            target="_blank">
            这里
          </Typography.Link>
          申请
        </p>
      </Form.Item>

      <Form.Item label="和风天气Key">
        <Input type="text" value={getwtKey} onChange={handleChange(setGetwtKey)} />
        <p className="comment">
          点击
          <Typography.Link href="https://console.qweather.com/#/apps" target="_blank">
            这里
          </Typography.Link>
          申请
        </p>
      </Form.Item>

      <Form.Item label="OpenAI API Key">
        <Input
          type="text"
          value={openAiApiKey}
          onChange={handleChange(setOpenAiApiKey)}
          placeholder="如需启用cclite，则必填"
        />
      </Form.Item>

      <Form.Item label="OpenAI API Base">
        <Input
          type="text"
          value={openAiApiBase}
          onChange={handleChange(setOpenAiApiBase)}
          placeholder="不填写则默认使用https://api.openai.com/v1"
        />
        {/* <p className="comment">OpenAI服务地址</p> */}
      </Form.Item>

      <Form.Item label="OpanAI函数模型">
        <Radio.Group value={functionsOpenaiModel} onChange={handleRadioChange(setFunctionsOpenaiModel)}>
          {availOpenAiModel.map((value) => (
            <Radio key={value} value={value}>
              {value}
            </Radio>
          ))}
        </Radio.Group>
        <p className="comment">用于判断用户意图</p>
      </Form.Item>

      <Form.Item label="OpanAI内容模型">
        <Radio.Group value={assistantOpenaiModel} onChange={handleRadioChange(setAssistantOpenaiModel)}>
          {availOpenAiModel.map((value) => (
            <Radio key={value} value={value}>
              {value}
            </Radio>
          ))}
        </Radio.Group>
        <p className="comment">用于总结内容</p>
      </Form.Item>

      {/* <Form.Item label="CC API Base">
        <Input type="text" value={ccApiBase} onChange={handleChange(setCcApiBase)} />
        <p className="comment">用于</p>
      </Form.Item> */}

      {/* <Form.Item label="各种功能定制提示词">
        {Object.keys(prompt).map((key) => (
          <div key={key}>
            <h4>{key}</h4>
            <Input.TextArea
              value={prompt[key]}
              onChange={(e) => updatePrompt(key, e.target.value)}
              autoSize={{ minRows: 2, maxRows: 4 }}
              showCount={true}
            />
          </div>
        ))}
      </Form.Item> */}
    </>
  );
};

export default CcliteConfig;
