import React from "react";
import { Form, Divider, Switch, Input, Radio } from "antd";
import { handleChange, handleRadioChange, handleSwitchChange } from "./Handlers";

interface Props {
  speechRecognition: boolean;
  setSpeechRecognition: (value: boolean) => void;
  groupSpeechRecognition: boolean;
  setGroupSpeechRecognition: (value: boolean) => void;
  voiceReplyVoice: boolean;
  setVoiceReplyVoice: (value: boolean) => void;
  alwaysReplyVoice: boolean;
  setAlwaysReplyVoice: (value: boolean) => void;
  voiceToText: string;
  setVoiceToText: (value: string) => void;
  textToVoice: string;
  setTextToVoice: (value: string) => void;
  textToVoiceModel: string;
  setTextToVoiceModel: (value: string) => void;
  ttsVoiceId: string;
  setTtsVoiceId: (value: string) => void;

  voiceToTextApiKey: string;
  setVoiceToTextApiKey: (value: string) => void;
  voiceToTextApiBase: string;
  setVoiceToTextApiBase: (value: string) => void;
  textToVoiceApiKey: string;
  setTextToVoiceApiKey: (value: string) => void;
  textToVoiceApiBase: string;
  setTextToVoiceApiBase: (value: string) => void;

  singleChatVoiceEchoSwitch: boolean;
  setSingleChatVoiceEchoSwitch: (value: boolean) => void;
  singleChatVoiceEchoPrefix: string;
  setSingleChatVoiceEchoPrefix: (value: string) => void;
  singleChatVoiceEchoSuffix: string;
  setSingleChatVoiceEchoSuffix: (value: string) => void;

  singleChatVoiceReplySwitch: boolean;
  setSingleChatVoiceReplySwitch: (value: boolean) => void;
  singleChatVoiceReplyPrefix: string;
  setSingleChatVoiceReplyPrefix: (value: string) => void;
  singleChatVoiceReplySuffix: string;
  setSingleChatVoiceReplySuffix: (value: string) => void;

  groupChatVoiceEchoSwitch: boolean;
  setGroupChatVoiceEchoSwitch: (value: boolean) => void;
  groupChatVoiceEchoPrefix: string;
  setGroupChatVoiceEchoPrefix: (value: string) => void;
  groupChatVoiceEchoSuffix: string;
  setGroupChatVoiceEchoSuffix: (value: string) => void;

  groupChatVoiceReplySwitch: boolean;
  setGroupChatVoiceReplySwitch: (value: boolean) => void;
  groupChatVoiceReplyPrefix: string;
  setGroupChatVoiceReplyPrefix: (value: string) => void;
  groupChatVoiceReplySuffix: string;
  setGroupChatVoiceReplySuffix: (value: string) => void;
}

const openaiTtsVoiceIds = ["alloy", "echo", "fable", "onyx", "nova", "shimmer"];
const openaiTextToVoiceModel = ["tts-1", "tts-1-hd"];

const VoiceAndServiceConfig: React.FC<Props> = ({
  speechRecognition,
  setSpeechRecognition,
  groupSpeechRecognition,
  setGroupSpeechRecognition,
  voiceReplyVoice,
  setVoiceReplyVoice,
  alwaysReplyVoice,
  setAlwaysReplyVoice,
  voiceToText,
  setVoiceToText,
  textToVoice,
  setTextToVoice,
  textToVoiceModel,
  setTextToVoiceModel,
  ttsVoiceId,
  setTtsVoiceId,

  voiceToTextApiKey,
  setVoiceToTextApiKey,
  voiceToTextApiBase,
  setVoiceToTextApiBase,
  textToVoiceApiKey,
  setTextToVoiceApiKey,
  textToVoiceApiBase,
  setTextToVoiceApiBase,

  singleChatVoiceEchoPrefix,
  setSingleChatVoiceEchoPrefix,

  singleChatVoiceReplyPrefix,
  setSingleChatVoiceReplyPrefix,

  singleChatVoiceEchoSuffix,
  setSingleChatVoiceEchoSuffix,

  singleChatVoiceReplySuffix,
  setSingleChatVoiceReplySuffix,

  singleChatVoiceEchoSwitch,
  setSingleChatVoiceEchoSwitch,
  singleChatVoiceReplySwitch,
  setSingleChatVoiceReplySwitch,

  groupChatVoiceEchoSwitch,
  setGroupChatVoiceEchoSwitch,
  groupChatVoiceReplySwitch,
  setGroupChatVoiceReplySwitch,
  groupChatVoiceEchoPrefix,
  setGroupChatVoiceEchoPrefix,

  groupChatVoiceReplyPrefix,
  setGroupChatVoiceReplyPrefix,

  groupChatVoiceEchoSuffix,
  setGroupChatVoiceEchoSuffix,

  groupChatVoiceReplySuffix,
  setGroupChatVoiceReplySuffix,
}) => {
  return (
    <>
      <Divider orientation="left">
        <div id="voice">语音配置</div>
      </Divider>
      <Form.Item label="开启语音识别">
        <Switch checked={speechRecognition} onChange={handleSwitchChange(setSpeechRecognition)} />
      </Form.Item>
      <Form.Item label="开启群组语音识别">
        <Switch checked={groupSpeechRecognition} onChange={handleSwitchChange(setGroupSpeechRecognition)} />
      </Form.Item>
      <Form.Item label="使用语音回复语音">
        <Switch checked={voiceReplyVoice} onChange={handleSwitchChange(setVoiceReplyVoice)} />
        <div className="comment">需要设置对应语音合成引擎的api key，暂时仅开放openai</div>
      </Form.Item>
      <Form.Item label="一直使用语音回复">
        <Switch checked={alwaysReplyVoice} onChange={handleSwitchChange(setAlwaysReplyVoice)} />
      </Form.Item>
      <Form.Item label="语音识别引擎">
        <Input type="text" value={voiceToText} onChange={handleChange(setVoiceToText)} />
        <div className="comment">支持openai,baidu,google,azure</div>
      </Form.Item>
      <Form.Item label="语音识别引擎API Key">
        <Input
          type="text"
          value={voiceToTextApiKey}
          onChange={handleChange(setVoiceToTextApiKey)}
          placeholder="不填写则默认使用聊天API Key"
        />
      </Form.Item>
      <Form.Item label="语音识别引擎API Base">
        <Input
          type="text"
          value={voiceToTextApiBase}
          onChange={handleChange(setVoiceToTextApiBase)}
          placeholder="不填写则默认使用https://api.openai.com/v1"
        />
      </Form.Item>
      <Form.Item label="语音合成引擎">
        <Input type="text" value={textToVoice} onChange={handleChange(setTextToVoice)} />
        <div className="comment">支持openai,baidu,google,pytts(offline),azure,elevenlabs</div>
      </Form.Item>
      <Form.Item label="语音合成引擎API Key">
        <Input
          type="text"
          value={textToVoiceApiKey}
          onChange={handleChange(setTextToVoiceApiKey)}
          placeholder="不填写则默认使用聊天API Key"
        />
      </Form.Item>
      <Form.Item label="语音合成引擎API Base">
        <Input
          type="text"
          value={textToVoiceApiBase}
          onChange={handleChange(setTextToVoiceApiBase)}
          placeholder="不填写则默认使用https://api.openai.com/v1"
        />
      </Form.Item>
      <Form.Item label="TTS模型">
        <Radio.Group value={textToVoiceModel} onChange={handleRadioChange(setTextToVoiceModel)}>
          {openaiTextToVoiceModel.map((m) => (
            <Radio key={m} value={m}>
              {m}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item label="TTS发音人">
        <Radio.Group value={ttsVoiceId} onChange={handleRadioChange(setTtsVoiceId)}>
          {openaiTtsVoiceIds.map((m) => (
            <Radio key={m} value={m}>
              {m}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>

      <Form.Item label="开启私聊语音识别回显">
        <Switch checked={singleChatVoiceEchoSwitch} onChange={handleSwitchChange(setSingleChatVoiceEchoSwitch)} />
      </Form.Item>
      {singleChatVoiceEchoSwitch && (
        <>
          <Form.Item label="私聊语音识别回显的前缀">
            <Input
              type="text"
              value={singleChatVoiceEchoPrefix}
              onChange={handleChange(setSingleChatVoiceEchoPrefix)}
            />
          </Form.Item>

          <Form.Item label="私聊语音识别回显的后缀">
            <Input
              type="text"
              value={singleChatVoiceEchoSuffix}
              onChange={handleChange(setSingleChatVoiceEchoSuffix)}
            />
          </Form.Item>
        </>
      )}

      <Form.Item label="开启私聊语音合成前显示文本">
        <Switch checked={singleChatVoiceReplySwitch} onChange={handleSwitchChange(setSingleChatVoiceReplySwitch)} />
      </Form.Item>
      {singleChatVoiceReplySwitch && (
        <>
          <Form.Item label="私聊语音合成前显示文本的前缀">
            <Input
              type="text"
              value={singleChatVoiceReplyPrefix}
              onChange={handleChange(setSingleChatVoiceReplyPrefix)}
            />
          </Form.Item>
          <Form.Item label="私聊语音合成前显示文本的后缀">
            <Input
              type="text"
              value={singleChatVoiceReplySuffix}
              onChange={handleChange(setSingleChatVoiceReplySuffix)}
            />
          </Form.Item>
        </>
      )}

      <Form.Item label="开启群聊语音识别回显">
        <Switch checked={groupChatVoiceEchoSwitch} onChange={handleSwitchChange(setGroupChatVoiceEchoSwitch)} />
      </Form.Item>
      {groupChatVoiceEchoSwitch && (
        <>
          <Form.Item label="群聊语音识别回显的前缀">
            <Input type="text" value={groupChatVoiceEchoPrefix} onChange={handleChange(setGroupChatVoiceEchoPrefix)} />
          </Form.Item>
          <Form.Item label="群聊语音识别回显的后缀">
            <Input type="text" value={groupChatVoiceEchoSuffix} onChange={handleChange(setGroupChatVoiceEchoSuffix)} />
          </Form.Item>
        </>
      )}

      <Form.Item label="开启群聊语音合成前显示文本">
        <Switch checked={groupChatVoiceReplySwitch} onChange={handleSwitchChange(setGroupChatVoiceReplySwitch)} />
      </Form.Item>
      {groupChatVoiceReplySwitch && (
        <>
          <Form.Item label="群聊语音合成前显示文本的前缀">
            <Input
              type="text"
              value={groupChatVoiceReplyPrefix}
              onChange={handleChange(setGroupChatVoiceReplyPrefix)}
            />
          </Form.Item>
          <Form.Item label="群聊语音合成前显示文本的后缀">
            <Input
              type="text"
              value={groupChatVoiceReplySuffix}
              onChange={handleChange(setGroupChatVoiceReplySuffix)}
            />
          </Form.Item>
        </>
      )}
    </>
  );
};

export default VoiceAndServiceConfig;
