import { Button, Form, Input } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { login } from "./authSlice";
import { getApiUrl } from "./shared";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState(""); // 添加一个密码状态

  const onFinish = async (values: any) => {
    try {
      const apiURL = getApiUrl("/api/auth");
      const response = await axios.post(apiURL, { password });
      const { success, message } = response.data;

      if (success) {
        // 认证成功的逻辑
        dispatch(login());
        navigate("/");
      } else {
        // 认证失败的逻辑
        console.error(message);
        alert(message);
      }
    } catch (error) {
      console.error(error);
      alert(String(error));
    }
  };

  return (
    <div className="Login">
      <h1>管理员登录</h1>
      <Form className="login-form" onFinish={onFinish}>
        <Form.Item name="password" rules={[{ required: true, message: "请输入密码!" }]}>
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="密码" // 添加一个占位符
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          登录
        </Button>
      </Form>
    </div>
  );
};

export default Login;
