import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import axios from "axios";
import { getApiUrl } from "../shared";

const QRCodeComponent: React.FC = () => {
  const [url, setUrl] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [godcmd, setGodcmd] = useState<string>("");
  const [userID, setUserID] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchUrlAndStatus = async () => {
      try {
        const apiURL = getApiUrl("/api/login_status");

        const response = await axios.get(apiURL, {
          cancelToken: source.token,
        });
        setIsLoading(response.data.isLoading);
        setIsLoggedIn(response.data.isLoggedIn);
        setUrl(response.data.url);
        setUserID(response.data.user_id);
        setNickname(response.data.nickname);
        setGodcmd(response.data.godcmd);
      } catch (error: any) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.error("Error fetching the data:", error.message);
        }
      }
    };

    const intervalId = setInterval(fetchUrlAndStatus, 500);

    return () => {
      source.cancel("Component got unmounted");
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="qrcode">
      <h3>{isLoggedIn ? "服务状态" : "微信扫码登录"}</h3>

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {!isLoggedIn && url && (
            <>
              <QRCode value={url} />
              <p className="comment">扫码登录后需要等待15秒左右</p>
              <br />
            </>
          )}
          {isLoggedIn && userID && <p>当前登录账号昵称: {nickname}</p>}
          {isLoggedIn && godcmd && <p>Godcmd密码: {godcmd}</p>}
        </div>
      )}
    </div>
  );
};

export default QRCodeComponent;
