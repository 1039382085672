import { Form, Input, Switch, InputNumber } from "antd";
import React from "react";
import EditableTagGroup from "./EditableTagGroup";
import { handleChange, handleSwitchChange } from "./Handlers";

interface MidjourneyProxyOnWechatProps {
  mjUrl: string;
  setMjUrl: (value: string) => void;
  mjApiSecret: string;
  setMjApiSecret: (value: string) => void;
  mjTip: boolean;
  setMjTip: (value: boolean) => void;
  showImgUrl: boolean;
  setShowImgUrl: (value: boolean) => void;
  mjAdminPassword: string;
  setMjAdminPassword: (value: string) => void;
  discordappProxy: string;
  setDiscordappProxy: (value: string) => void;
  dailyLimit: number;
  setDailyLimit: (value: number) => void;
  imaginePrefix: string[];
  setImaginePrefix: (value: string[]) => void;
  fetchPrefix: string[];
  setFetchPrefix: (value: string[]) => void;
  upPrefix: string[];
  setUpPrefix: (value: string[]) => void;
  padPrefix: string[];
  setPadPrefix: (value: string[]) => void;
  blendPrefix: string[];
  setBlendPrefix: (value: string[]) => void;
  describePrefix: string[];
  setDescribePrefix: (value: string[]) => void;
  queuePrefix: string[];
  setQueuePrefix: (value: string[]) => void;
  endPrefix: string[];
  setEndPrefix: (value: string[]) => void;
  rerollPrefix: string[];
  setRerollPrefix: (value: string[]) => void;
}

const MidjourneyProxyOnWechat: React.FC<MidjourneyProxyOnWechatProps> = ({
  mjUrl,
  setMjUrl,
  mjApiSecret,
  setMjApiSecret,
  mjTip,
  setMjTip,

  mjAdminPassword,
  setMjAdminPassword,
  discordappProxy,
  setDiscordappProxy,
  showImgUrl,
  setShowImgUrl,
  dailyLimit,
  setDailyLimit,
  imaginePrefix,
  setImaginePrefix,
  fetchPrefix,
  setFetchPrefix,
  upPrefix,
  setUpPrefix,
  padPrefix,
  setPadPrefix,
  blendPrefix,
  setBlendPrefix,
  describePrefix,
  setDescribePrefix,
  queuePrefix,
  setQueuePrefix,
  endPrefix,
  setEndPrefix,
  rerollPrefix,
  setRerollPrefix,
}) => {
  return (
    <>
      <Form.Item label="MJ服务URL">
        <Input type="text" value={mjUrl} onChange={handleChange(setMjUrl)} />
      </Form.Item>

      <Form.Item label="MJ服务SECRET">
        <Input type="text" value={mjApiSecret} onChange={handleChange(setMjApiSecret)} />
      </Form.Item>

      <Form.Item label="请求提示">
        <Switch checked={mjTip} onChange={handleSwitchChange(setMjTip)} />
        <p className="comment">是否发送请求提示，让漫长的等待不会枯燥，如果嫌啰嗦可关闭（最终只发图片或者描述）</p>
      </Form.Item>

      <Form.Item label="管理密码">
        <Input type="text" value={mjAdminPassword} onChange={handleChange(setMjAdminPassword)} />
      </Form.Item>

      <Form.Item label="显示图片链接">
        <Switch checked={showImgUrl} onChange={handleSwitchChange(setShowImgUrl)} />
      </Form.Item>

      <Form.Item label="图片链接反代">
        <Input type="text" value={discordappProxy} onChange={handleChange(setDiscordappProxy)} />
      </Form.Item>

      <Form.Item label="每日画图限次">
        <InputNumber
          min={1}
          value={dailyLimit}
          onChange={(value: number | null) => {
            setDailyLimit(value!);
          }}
        />
        <p className="comment">限制普通用户每日作图数，管理员和白名单用户不受限</p>
      </Form.Item>

      <Form.Item label="画图前缀">
        <EditableTagGroup tags={imaginePrefix} setTags={setImaginePrefix} />
      </Form.Item>

      <Form.Item label="任务查询前缀">
        <EditableTagGroup tags={fetchPrefix} setTags={setFetchPrefix} />
      </Form.Item>

      <Form.Item label="放大和变换前缀">
        <EditableTagGroup tags={upPrefix} setTags={setUpPrefix} />
      </Form.Item>

      <Form.Item label="垫图前缀">
        <EditableTagGroup tags={padPrefix} setTags={setPadPrefix} />
      </Form.Item>

      <Form.Item label="混图前缀">
        <EditableTagGroup tags={blendPrefix} setTags={setBlendPrefix} />
      </Form.Item>

      <Form.Item label="结束前缀">
        <EditableTagGroup tags={endPrefix} setTags={setEndPrefix} />
        <p className="comment">结束存储打包发送任务（目前用于混图）</p>
      </Form.Item>
      <Form.Item label="识图前缀">
        <EditableTagGroup tags={describePrefix} setTags={setDescribePrefix} />
      </Form.Item>

      <Form.Item label="查询前缀">
        <EditableTagGroup tags={queuePrefix} setTags={setQueuePrefix} />
        <p className="comment">查询正在执行中任务</p>
      </Form.Item>

      <Form.Item label="重绘前缀">
        <EditableTagGroup tags={rerollPrefix} setTags={setRerollPrefix} />
      </Form.Item>
    </>
  );
};

export default MidjourneyProxyOnWechat;
