import { Divider } from "antd";
import React from "react";
import "./Dashboard.scss";
import Sidebar from "./Sidebar";
import FormConfig from "./components/FormConfig";
import FormPlugins from "./components/FormPlugins";
import FormPassword from "./components/FormPassword";

const Dashboard: React.FC = () => {
  return (
    <>
      <div className="Sidebar">
        <Sidebar />
      </div>

      <div className="App">
        <Divider>
          <h2 id="general">通用配置</h2>
        </Divider>

        <FormPassword />

        <FormConfig />

        <Divider>
          <h2 id="plugin">插件配置</h2>
        </Divider>

        <FormPlugins />
      </div>
    </>
  );
};

export default Dashboard;
