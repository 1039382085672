import { Button, Form } from "antd";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { formItemLayout, getApiUrl } from "../shared";
import ApiConfig from "./ApiConfig";
import ChatConfig from "./ChatConfig";
import GroupChatConfig from "./GroupChatConfig";
// import LinkAIConfig from "./LinkAIConfig";
import SingleChatConfig from "./SingleChatConfig";
import VoiceAndServiceConfig from "./VoiceAndServiceConfig";
import ImageConfig from "./ImageConfig";

interface ConfigData {
  open_ai_api_key: string;
  open_ai_api_base: string;
  service: string;
  model: string;
  proxy: string;
  rate_limit_chatgpt: number;

  use_dalle3: boolean;
  dalle3_api_key: string;
  dalle3_api_base: string;
  rate_limit_dalle: number;
  dalle3_daily_limit: number;

  use_azure_chatgpt: boolean;
  azure_deployment_id: string;
  azure_api_version: string;

  gemini_api_key: string;

  baidu_wenxin_model: string;
  baidu_wenxin_api_key: string;
  baidu_wenxin_secret_key: string;

  xunfei_app_id: string;
  xunfei_api_key: string;
  xunfei_api_secret: string;

  single_chat_prefix: string[];
  single_chat_reply_prefix: string;
  single_chat_reply_suffix: string;

  group_chat_prefix: string[];
  group_chat_reply_prefix: string;
  group_chat_reply_suffix: string;

  group_chat_keyword: string[];
  group_at_off: boolean;
  group_name_white_list: string[];
  group_chat_in_one_session: string[];
  group_welcome_msg: string;

  image_create_prefix: string[];
  speech_recognition: boolean;
  group_speech_recognition: boolean;
  trigger_by_self: boolean;
  debug: boolean;

  voice_reply_voice: boolean;
  always_reply_voice: boolean;
  voice_to_text: string;
  text_to_voice: string;
  text_to_voice_model: string;
  tts_voice_id: string;

  voice_to_text_api_key: string;
  voice_to_text_api_base: string;
  text_to_voice_api_key: string;
  text_to_voice_api_base: string;

  single_chat_voice_echo_switch: boolean;
  single_chat_voice_echo_prefix: string;
  single_chat_voice_echo_suffix: string;
  single_chat_voice_reply_switch: boolean;
  single_chat_voice_reply_prefix: string;
  single_chat_voice_reply_suffix: string;

  group_chat_voice_echo_switch: boolean;
  group_chat_voice_echo_prefix: string;
  group_chat_voice_echo_suffix: string;
  group_chat_voice_reply_switch: boolean;
  group_chat_voice_reply_prefix: string;
  group_chat_voice_reply_suffix: string;

  appdata_dir: string;
  character_desc: string;
  subscribe_msg: string;
  use_linkai: boolean;
  linkai_api_key: string;
  linkai_app_code: string;
  conversation_max_tokens: number;
  hot_reload: boolean;
}

const FormConfig: React.FC = () => {
  const [configData, setConfigData] = useState<ConfigData>({
    open_ai_api_key: "",
    open_ai_api_base: "https://api.openai.com/v1",
    service: "openai",
    model: "gpt-3.5-turbo",
    proxy: "",
    rate_limit_chatgpt: 3,

    use_dalle3: false,
    dalle3_api_key: "",
    dalle3_api_base: "",
    rate_limit_dalle: 1,
    dalle3_daily_limit: 5,

    use_azure_chatgpt: false,
    azure_deployment_id: "",
    azure_api_version: "",

    gemini_api_key: "",

    baidu_wenxin_model: "eb-instant",
    baidu_wenxin_api_key: "",
    baidu_wenxin_secret_key: "",

    xunfei_app_id: "",
    xunfei_api_key: "",
    xunfei_api_secret: "",

    single_chat_prefix: ["bot", "@bot"],
    single_chat_reply_prefix: "[bot] ",
    single_chat_reply_suffix: "",

    group_chat_prefix: ["@bot"],
    group_chat_reply_prefix: "",
    group_chat_reply_suffix: "",
    group_chat_keyword: [],
    group_at_off: false,
    group_name_white_list: [],
    group_chat_in_one_session: [],
    group_welcome_msg: "",

    image_create_prefix: [],
    speech_recognition: false,
    group_speech_recognition: false,
    trigger_by_self: false,
    debug: false,

    voice_reply_voice: false,
    always_reply_voice: false,
    voice_to_text: "openai",
    text_to_voice: "openai",
    text_to_voice_model: "tts-1",
    tts_voice_id: "alloy",

    voice_to_text_api_key: "",
    voice_to_text_api_base: "",
    text_to_voice_api_key: "",
    text_to_voice_api_base: "",

    single_chat_voice_echo_switch: false,
    single_chat_voice_echo_prefix: "收到：",
    single_chat_voice_echo_suffix: "",
    single_chat_voice_reply_switch: false,
    single_chat_voice_reply_prefix: "回复：",
    single_chat_voice_reply_suffix: "",

    group_chat_voice_echo_switch: false,
    group_chat_voice_echo_prefix: "收到：",
    group_chat_voice_echo_suffix: "",
    group_chat_voice_reply_switch: false,
    group_chat_voice_reply_prefix: "回复：",
    group_chat_voice_reply_suffix: "",

    appdata_dir: "appdata",
    character_desc: "",
    subscribe_msg: "",
    use_linkai: false,
    linkai_api_key: "",
    linkai_app_code: "",
    conversation_max_tokens: 2500,
    hot_reload: false,
  });

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const apiURL = getApiUrl("/api/config");

        const response = await axios.get(apiURL);
        setConfigData((prevData) => ({
          ...prevData,
          ...response.data,
        }));
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };

    fetchConfigData();
  }, []);

  const onFinish = async (values: any) => {
    console.log("Submit:", values);

    try {
      const apiURL = getApiUrl("/api/generate_config");

      const response = await axios.post(apiURL, configData);
      if (response.data.success) {
        alert("通用配置保存成功，正在重启Bot");
      } else {
        alert("Error: " + response.data.error);
      }
    } catch (error) {
      alert("Network error!");
    }
  };

  // const buttonItemLayout = {
  //   wrapperCol: { span: 14, offset: 6 },
  // };
  const parentRef = useRef<HTMLDivElement>(null);
  const [buttonStyle, setButtonStyle] = useState<React.CSSProperties>({});

  const handleScroll = () => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();

      if (window.innerHeight > parentRect.bottom) {
        // 如果按钮超出了父组件，将其固定在父组件的底部
        setButtonStyle({ position: "absolute" });
      } else {
        // 否则，让它浮动
        setButtonStyle({ position: "fixed" });
      }
    } else {
      setButtonStyle({ position: "absolute" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div ref={parentRef} className="parent">
      <Form {...formItemLayout} className="config-form" layout="horizontal" onFinish={onFinish}>
        <ApiConfig
          apiKey={configData.open_ai_api_key}
          setApiKey={(apiKey) =>
            setConfigData((prevData) => ({
              ...prevData,
              open_ai_api_key: apiKey,
            }))
          }
          apiBase={configData.open_ai_api_base}
          setApiBase={(apiBase) =>
            setConfigData((prevData) => ({
              ...prevData,
              open_ai_api_base: apiBase,
            }))
          }
          service={configData.service}
          setService={(service) => setConfigData((prevData) => ({ ...prevData, service }))}
          model={configData.model}
          setModel={(model) => setConfigData((prevData) => ({ ...prevData, model }))}
          // proxy={configData.proxy}
          // setProxy={(proxy) => setConfigData((prevData) => ({ ...prevData, proxy }))}
          useAzureChatGPT={configData.use_azure_chatgpt}
          setUseAzureChatGPT={(use_azure_chatgpt) =>
            setConfigData((prevData) => ({
              ...prevData,
              use_azure_chatgpt,
            }))
          }
          azureDeploymentId={configData.azure_deployment_id}
          setAzureDeploymentId={(azure_deployment_id) =>
            setConfigData((prevData) => ({
              ...prevData,
              azure_deployment_id,
            }))
          }
          azureApiVersion={configData.azure_api_version}
          setAzureApiVersion={(azure_api_version) =>
            setConfigData((prevData) => ({
              ...prevData,
              azure_api_version,
            }))
          }
          rateLimitChatGPT={configData.rate_limit_chatgpt}
          setRateLimitChatGPT={(rate_limit_chatgpt) =>
            setConfigData((prevData) => ({
              ...prevData,
              rate_limit_chatgpt,
            }))
          }
          geminiApiKey={configData.gemini_api_key}
          setGeminiApiKey={(gemini_api_key) =>
            setConfigData((prevData) => ({
              ...prevData,
              gemini_api_key,
            }))
          }
          baiduWenxinModel={configData.baidu_wenxin_model}
          setBaiduWenxinModel={(baidu_wenxin_model) =>
            setConfigData((prevData) => ({
              ...prevData,
              baidu_wenxin_model,
            }))
          }
          baiduWenxinApiKey={configData.baidu_wenxin_api_key}
          setBaiduWenxinApiKey={(baidu_wenxin_api_key) =>
            setConfigData((prevData) => ({
              ...prevData,
              baidu_wenxin_api_key,
            }))
          }
          baiduWenxinSecretKey={configData.baidu_wenxin_secret_key}
          setBaiduWenxinSecretKey={(baidu_wenxin_secret_key) =>
            setConfigData((prevData) => ({
              ...prevData,
              baidu_wenxin_secret_key,
            }))
          }
          xunfeiAppId={configData.xunfei_app_id}
          setXunfeiAppId={(xunfei_app_id) =>
            setConfigData((prevData) => ({
              ...prevData,
              xunfei_app_id,
            }))
          }
          xunfeiApiKey={configData.xunfei_api_key}
          setXunfeiApiKey={(xunfei_api_key) =>
            setConfigData((prevData) => ({
              ...prevData,
              xunfei_api_key,
            }))
          }
          xunfeiApiSecret={configData.xunfei_api_secret}
          setXunfeiApiSecret={(xunfei_api_secret) =>
            setConfigData((prevData) => ({
              ...prevData,
              xunfei_api_secret,
            }))
          }
        />
        <ChatConfig
          characterDesc={configData.character_desc}
          setCharacterDesc={(character_desc) =>
            setConfigData((prevData) => ({
              ...prevData,
              character_desc,
            }))
          }
          conversationMaxTokens={configData.conversation_max_tokens}
          setConversationMaxTokens={(conversation_max_tokens) =>
            setConfigData((prevData) => ({
              ...prevData,
              conversation_max_tokens,
            }))
          }
          subscribeMsg={configData.subscribe_msg}
          setSubscribeMsg={(subscribe_msg) =>
            setConfigData((prevData) => ({
              ...prevData,
              subscribe_msg,
            }))
          }
          hotReload={configData.hot_reload}
          setHotReload={(hot_reload) =>
            setConfigData((prevData) => ({
              ...prevData,
              hot_reload,
            }))
          }
          triggerBySelf={configData.trigger_by_self}
          setTriggerBySelf={(trigger_by_self) =>
            setConfigData((prevData) => ({
              ...prevData,
              trigger_by_self,
            }))
          }
          debug={configData.debug}
          setDebug={(debug) =>
            setConfigData((prevData) => ({
              ...prevData,
              debug,
            }))
          }
          // appDataDir={configData.appdata_dir}
          // setAppDataDir={(appdata_dir) =>
          //   setConfigData((prevData) => ({
          //     ...prevData,
          //     appdata_dir,
          //   }))
          // }
        />
        {/* <LinkAIConfig
          useLinkAI={configData.use_linkai}
          setUseLinkAI={(use_linkai) =>
            setConfigData((prevData) => ({
              ...prevData,
              use_linkai,
            }))
          }
          linkAIApiKey={configData.linkai_api_key}
          setLinkAIApiKey={(linkai_api_key) =>
            setConfigData((prevData) => ({
              ...prevData,
              linkai_api_key,
            }))
          }
          linkAIAppCode={configData.linkai_app_code}
          setLinkAIAppCode={(linkai_app_code) =>
            setConfigData((prevData) => ({
              ...prevData,
              linkai_app_code,
            }))
          }
        /> */}
        <ImageConfig
          imageCreatePrefix={configData.image_create_prefix}
          setImageCreatePrefix={(image_create_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              image_create_prefix,
            }))
          }
          useDalle3={configData.use_dalle3}
          setUseDalle3={(use_dalle3) =>
            setConfigData((prevData) => ({
              ...prevData,
              use_dalle3,
            }))
          }
          dalle3ApiKey={configData.dalle3_api_key}
          setDalle3ApiKey={(apiKey) =>
            setConfigData((prevData) => ({
              ...prevData,
              dalle3_api_key: apiKey,
            }))
          }
          dalle3ApiBase={configData.dalle3_api_base}
          setDalle3ApiBase={(apiBase) =>
            setConfigData((prevData) => ({
              ...prevData,
              dalle3_api_base: apiBase,
            }))
          }
          rateLimitDalle={configData.rate_limit_dalle}
          setRateLimitDalle={(rate_limit_dalle) => setConfigData((prevData) => ({ ...prevData, rate_limit_dalle }))}
          dailyLimitDalle={configData.dalle3_daily_limit}
          setDailyLimitDalle={(dalle3_daily_limit) =>
            setConfigData((prevData) => ({
              ...prevData,
              dalle3_daily_limit,
            }))
          }
        />
        <VoiceAndServiceConfig
          speechRecognition={configData.speech_recognition}
          setSpeechRecognition={(speech_recognition) =>
            setConfigData((prevData) => ({
              ...prevData,
              speech_recognition,
            }))
          }
          groupSpeechRecognition={configData.group_speech_recognition}
          setGroupSpeechRecognition={(group_speech_recognition) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_speech_recognition,
            }))
          }
          voiceReplyVoice={configData.voice_reply_voice}
          setVoiceReplyVoice={(voice_reply_voice) =>
            setConfigData((prevData) => ({
              ...prevData,
              voice_reply_voice,
            }))
          }
          alwaysReplyVoice={configData.always_reply_voice}
          setAlwaysReplyVoice={(always_reply_voice) =>
            setConfigData((prevData) => ({
              ...prevData,
              always_reply_voice,
            }))
          }
          voiceToText={configData.voice_to_text}
          setVoiceToText={(voice_to_text) =>
            setConfigData((prevData) => ({
              ...prevData,
              voice_to_text,
            }))
          }
          voiceToTextApiKey={configData.voice_to_text_api_key}
          setVoiceToTextApiKey={(voice_to_text_api_key) =>
            setConfigData((prevData) => ({
              ...prevData,
              voice_to_text_api_key,
            }))
          }
          voiceToTextApiBase={configData.voice_to_text_api_base}
          setVoiceToTextApiBase={(voice_to_text_api_base) =>
            setConfigData((prevData) => ({
              ...prevData,
              voice_to_text_api_base,
            }))
          }
          textToVoice={configData.text_to_voice}
          setTextToVoice={(text_to_voice) =>
            setConfigData((prevData) => ({
              ...prevData,
              text_to_voice,
            }))
          }
          textToVoiceApiKey={configData.text_to_voice_api_key}
          setTextToVoiceApiKey={(text_to_voice_api_key) =>
            setConfigData((prevData) => ({
              ...prevData,
              text_to_voice_api_key,
            }))
          }
          textToVoiceApiBase={configData.text_to_voice_api_base}
          setTextToVoiceApiBase={(text_to_voice_api_base) =>
            setConfigData((prevData) => ({
              ...prevData,
              text_to_voice_api_base,
            }))
          }
          textToVoiceModel={configData.text_to_voice_model}
          setTextToVoiceModel={(text_to_voice_model) =>
            setConfigData((prevData) => ({
              ...prevData,
              text_to_voice_model,
            }))
          }
          ttsVoiceId={configData.tts_voice_id}
          setTtsVoiceId={(tts_voice_id) =>
            setConfigData((prevData) => ({
              ...prevData,
              tts_voice_id,
            }))
          }
          singleChatVoiceReplyPrefix={configData.single_chat_voice_reply_prefix}
          setSingleChatVoiceReplyPrefix={(single_chat_voice_reply_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_voice_reply_prefix,
            }))
          }
          singleChatVoiceReplySuffix={configData.single_chat_voice_reply_suffix}
          setSingleChatVoiceReplySuffix={(single_chat_voice_reply_suffix) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_voice_reply_suffix,
            }))
          }
          singleChatVoiceEchoPrefix={configData.single_chat_voice_echo_prefix}
          setSingleChatVoiceEchoPrefix={(single_chat_voice_echo_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_voice_echo_prefix,
            }))
          }
          singleChatVoiceEchoSuffix={configData.single_chat_voice_echo_suffix}
          setSingleChatVoiceEchoSuffix={(single_chat_voice_echo_suffix) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_voice_echo_suffix,
            }))
          }
          groupChatVoiceReplyPrefix={configData.group_chat_voice_reply_prefix}
          setGroupChatVoiceReplyPrefix={(group_chat_voice_reply_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_voice_reply_prefix,
            }))
          }
          groupChatVoiceReplySuffix={configData.group_chat_voice_reply_suffix}
          setGroupChatVoiceReplySuffix={(group_chat_voice_reply_suffix) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_voice_reply_suffix,
            }))
          }
          groupChatVoiceEchoPrefix={configData.group_chat_voice_echo_prefix}
          setGroupChatVoiceEchoPrefix={(group_chat_voice_echo_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_voice_echo_prefix,
            }))
          }
          groupChatVoiceEchoSuffix={configData.group_chat_voice_echo_suffix}
          setGroupChatVoiceEchoSuffix={(group_chat_voice_echo_suffix) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_voice_echo_suffix,
            }))
          }
          singleChatVoiceEchoSwitch={configData.single_chat_voice_echo_switch}
          setSingleChatVoiceEchoSwitch={(single_chat_voice_echo_switch) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_voice_echo_switch,
            }))
          }
          singleChatVoiceReplySwitch={configData.single_chat_voice_reply_switch}
          setSingleChatVoiceReplySwitch={(single_chat_voice_reply_switch) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_voice_reply_switch,
            }))
          }
          groupChatVoiceEchoSwitch={configData.group_chat_voice_echo_switch}
          setGroupChatVoiceEchoSwitch={(group_chat_voice_echo_switch) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_voice_echo_switch,
            }))
          }
          groupChatVoiceReplySwitch={configData.group_chat_voice_reply_switch}
          setGroupChatVoiceReplySwitch={(group_chat_voice_reply_switch) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_voice_reply_switch,
            }))
          }
        />
        <SingleChatConfig
          singleChatPrefix={configData.single_chat_prefix}
          setSingleChatPrefix={(single_chat_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_prefix: single_chat_prefix.length > 0 ? single_chat_prefix : [""],
            }))
          }
          singleChatReplyPrefix={configData.single_chat_reply_prefix}
          setSingleChatReplyPrefix={(single_chat_reply_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_reply_prefix,
            }))
          }
          singleChatReplySuffix={configData.single_chat_reply_suffix}
          setSingleChatReplySuffix={(single_chat_reply_suffix) =>
            setConfigData((prevData) => ({
              ...prevData,
              single_chat_reply_suffix,
            }))
          }
        />
        <GroupChatConfig
          groupChatPrefix={configData.group_chat_prefix}
          setGroupChatPrefix={(group_chat_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_prefix,
            }))
          }
          groupChatReplyPrefix={configData.group_chat_reply_prefix}
          setGroupChatReplyPrefix={(group_chat_reply_prefix) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_reply_prefix,
            }))
          }
          groupChatReplySuffix={configData.group_chat_reply_suffix}
          setGroupChatReplySuffix={(group_chat_reply_suffix) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_reply_suffix,
            }))
          }
          groupChatKeyword={configData.group_chat_keyword}
          setGroupChatKeyword={(group_chat_keyword) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_keyword,
            }))
          }
          groupAtOff={configData.group_at_off}
          setGroupAtOff={(group_at_off) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_at_off,
            }))
          }
          groupNameWhiteList={configData.group_name_white_list}
          setGroupNameWhiteList={(group_name_white_list) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_name_white_list,
            }))
          }
          groupChatInOneSession={configData.group_chat_in_one_session}
          setGroupChatInOneSession={(group_chat_in_one_session) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_chat_in_one_session,
            }))
          }
          groupWelcomeMsg={configData.group_welcome_msg}
          setGroupWelcomeMsg={(group_welcome_msg) =>
            setConfigData((prevData) => ({
              ...prevData,
              group_welcome_msg,
            }))
          }
        />

        <br />
        <br />
        <br />

        {/* <Form.Item {...buttonItemLayout}> */}
        <Button type="primary" htmlType="submit" className="config-form-button floating-button" style={buttonStyle}>
          保存配置并重启Bot
        </Button>
        {/* </Form.Item> */}
      </Form>
    </div>
  );
};

export default FormConfig;
