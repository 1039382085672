import { Form, Input } from "antd";
import React from "react";
import { handleChange } from "./Handlers";

interface GodcmdConfigProps {
  password: string;
  setPassword: (value: string) => void;
  // adminUsers: string[];
  // setAdminUsers: (value: string[]) => void;
}

const GodcmdConfig: React.FC<GodcmdConfigProps> = ({
  password,
  setPassword,
  // adminUsers,
  // setAdminUsers,
}) => {
  return (
    <>
      <Form.Item label="管理密码">
        <Input type="text" value={password} onChange={handleChange(setPassword)} />
      </Form.Item>

      {/* <Form.Item label="指定管理员">
        <EditableTagGroup tags={adminUsers} setTags={setAdminUsers} />
      </Form.Item> */}
    </>
  );
};

export default GodcmdConfig;
