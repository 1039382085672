import { Button, Form, Input, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { formItemLayout, getApiUrl } from "../shared";

interface ConfigData {
  password: string;
}
const FormPassword: React.FC = () => {
  const [configData, setConfigData] = useState<ConfigData>({
    password: "pass",
  });
  const [initialPassword, setInitialPassword] = useState<string>("");

  useEffect(() => {
    const fetchConfigData = async () => {
      try {
        const apiURL = getApiUrl("/api/config_password");

        const response = await axios.get(apiURL);
        setConfigData((prevData) => ({
          ...prevData,
          ...response.data,
        }));
        setInitialPassword(response.data.password);
      } catch (error) {
        console.error("Error fetching config data:", error);
      }
    };

    fetchConfigData();
  }, []);

  const onFinish = async (values: any) => {
    console.log(initialPassword, configData.password);

    if (initialPassword === configData.password) {
      alert("密码未修改，无需提交");
      return;
    }

    console.log("Submit:", values);

    try {
      const apiURL = getApiUrl("/api/save_password");

      const response = await axios.post(apiURL, configData);
      if (response.data.success) {
        alert("后台管理密码保存成功");
        setInitialPassword(configData.password);
      } else {
        alert("Error: " + response.data.error);
      }
    } catch (error) {
      alert("Network error!");
    }
  };

  return (
    <Form {...formItemLayout} className="config-form" layout="horizontal" onFinish={onFinish}>
      <Form.Item
        name="password"
        label="后台管理密码"
        rules={[{ required: true, message: "后台管理密码不能为空" }]}
        initialValue={configData.password}>
        <Space direction="horizontal">
          <Input.Password
            value={configData.password}
            onChange={(e) => setConfigData({ ...configData, password: e.target.value })}
          />
          <Button type="primary" htmlType="submit" disabled={!configData.password}>
            更新密码
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default FormPassword;
